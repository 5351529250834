import TableCell from "../TableCell";
import DetailRow from "../DetailRow";
import EditableTableCell from "../EditableTableCell";
import EditableDetailRow from "../EditableDetailRow";

const Dosage = ({ data ,idField ,editFlag }) => {

  
  return (
    <>
      <div className="table-responsive">
        <table class="table  mt-2  table-bordered mb-0">
          <thead>
            <tr className="table-light">
              <th style={{ width: "30%" }} scope="col">
                Label
              </th>
              <th scope="col">Value</th>
              <th scope="col">Min</th>
              <th scope="col">Max</th>
              <th scope="col">Additional Fields</th>
            </tr>
          </thead>
          <tbody>
            {data && 
            <tr>
              <td>
                <TableCell enableEdit={true} editFlag={editFlag}  data={data?.label["@none"]} idField={`${idField}.label["@none"]`}/>
              </td>
              <td>
                <TableCell enableEdit={true} editFlag={editFlag}  data={data.value} idField={`${idField}.value`} />
              </td>
              <td>
                <TableCell enableEdit={true} editFlag={editFlag}  data={data.min}  idField={`${idField}.min`}/>
              </td>
              <td>
                <TableCell enableEdit={true} editFlag={editFlag}  data={data.max}  idField={`${idField}.max`}/>
              </td>

              <td>
                {data.qualifier && (
                  <DetailRow enableEdit={true} editFlag={editFlag} label={"Qualifier"} data={data.qualifier} idField={`${idField}.qualifier`} highlight={true} />
                )}
                <DetailRow enableEdit={true} editFlag={editFlag} label={"Less Than"} data={data.lessThan} idField={`${idField}.lessThan`} />
                <DetailRow enableEdit={true} editFlag={editFlag} label={"Greater Than"} data={data.greaterThan}  idField={`${idField}.greaterThan`}/>

                {data.unitOfMeasurement && data.unitOfMeasurement.label && (
                  <>
                  <DetailRow
                    label={"Unit Of Measurement"}
                    data={data.unitOfMeasurement.label["@none"]}
                  />
                  {data.unitOfMeasurement && data.unitOfMeasurement.unitLongName && (
                    <DetailRow
                    label={"Unit Long Name"}
                    data={data.unitOfMeasurement.unitLongName}
                  />
                  )}
                  </>
                )}
                {data.unitOfMeasurement && data.unitOfMeasurement.symbol && (
                  <>
                  <DetailRow
                    label={"Unit Of Measurement"}
                    data={data.unitOfMeasurement.symbol["@none"]}
                  />
                  {data.unitOfMeasurement && data.unitOfMeasurement.unitLongName && (
                    <DetailRow
                    label={"Unit Long Name"}
                    data={data.unitOfMeasurement.unitLongName}
                    highlight={true}
                  />
                  )}
                  </>
                )}
              </td>
            </tr>
          }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Dosage;
