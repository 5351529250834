import TableCell from "../TableCell";
import DetailRow from "../DetailRow";
import EditableTableCell from "../EditableTableCell";

const CropEvent = ({ cropEventData ,idField , editFlag}) => {

  return (
    <>
    {cropEventData.map((data, i) => (
    <>
      <div className="fw-bold">Relative To Event:</div>

      <div className="table-responsive">
        <table class="table  mt-2  table-bordered mb-0">
          <thead>
            <tr className="table-light">
              <th style={{ width: "30%" }} scope="col">
                Label
              </th>
              <th scope="col">Type</th>
              <th scope="col">Additional Fields</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TableCell enableEdit={true} editFlag={editFlag} data={data.label["@none"]}
                idField={`${idField}[${i}].label["@none"]`} />
              </td>
              <td>
                <TableCell data={data.type} />
              </td>

              <td>
                {data.insect && (
                  <DetailRow
                    label={"Insect"}
                    data={data.insect.label["@none"]}
                  />
                )}

                {data.ploughedPriorToSowingPlanting && (
                  <>
                    <div className="fw-bold">
                      Ploughed Prior To Sowing Planting:
                    </div>
                    <TableCell enableEdit={true} editFlag={editFlag} data={data.ploughedPriorToSowingPlanting.toString()}
                    idField ={`${idField}[${i}].ploughedPriorToSowingPlanting`}/>
                  </>
                )}

                {data.crop && data.crop.label && (
                  <DetailRow label={"Crop"} data={data.crop.label["@none"]} />
                )}

                {data.pest && data.pest.label && (
                  <DetailRow label={"Pest"} data={data.pest.label["@none"]} />
                )}

                {data.weed && (
                  <DetailRow label={"Weed"} data={data.weed.label["@none"]} />
                )}

                {data.fungus && (
                  <DetailRow
                    label={"Fungus"}
                    data={data.fungus.label["@none"]}
                  />
                )}

                {data.fungalLifecycleStage && (
                  <DetailRow
                    label={"Fungal Life cycle Stage"}
                    data={data.fungalLifecycleStage.label["@none"]}
                  />
                )}

                {data.pestLifecycleStage && (
                  <DetailRow
                    label={"Pest Life cycle Stage"}
                    data={data.pestLifecycleStage.label["@none"]}
                  />
                )}

                {data.weedLifecycleStage && (
                  <DetailRow
                    label={"Weed Life cycle Stage"}
                    data={data.weedLifecycleStage.label["@none"]}
                  />
                )}

                {data.weedGrowthStage && (
                  <DetailRow
                    label={"Weed Growth Stage"}
                    data={data.weedGrowthStage.label["@none"]}
                  />
                )}

                {data.growthStage && (
                  <DetailRow
                    label={"Growth Stage"}
                    data={data.growthStage.label["@none"]}
                  />
                )}

                {data.cropLifecycleStage && (
                  <DetailRow
                    label={"Crop Life cycle Stage"}
                    data={data.cropLifecycleStage.label["@none"]}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </>
      ))}
    </>
  );
 
};

export default CropEvent;
