import { API_BASE_URL } from "./Constant";
import { dataDisplayRequest } from "../Auth/auth_service/AuthServiceHelper";
import { encryptData, decryptData } from "../Utils/Crypto";

/* Service layer class for all API related calls */
export default class AxiosService {
  getAllLabels(roles, status,selectedlanguage) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/getLabelsByClientByStatus?role=${roles}&status=${status}&selectedLanguage=${selectedlanguage}`,
      data: {},
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  labelStatusUpdate(label, status,userId) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/saveLabelStatus?labelId=${label}&status=${status}&userId=${userId}`,
      data: {},
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getLabelData(label) {
   // label = encryptData(label);
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/getLabelDetails?labelId=${label}`,
      data: {},
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getComments(label) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/getAllCommentsByLabel?labelId=${label}`,
      data: {},
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  saveComment(data, label, username) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/saveComments?labelId=${label}&username=${username}`,
      data: data,
    };
    return dataDisplayRequest(config).then((response) => {
    
      return response;
    });
  }

  getMetaInfo(vendor) {
    const config = {
      method: "post",
      url: API_BASE_URL + "/metaInfo",
      data: { vendor: vendor },
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  editComment(data,label) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/editComment?labelId=${label}`,
      data: data,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  deleteComment(commentId,label) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/deleteComment?labelId=${label}&commentId=${commentId}`,
      data:{},
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getAllTreeComments(label) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/getAllTreeCommentsByLabel?labelId=${label}`,
      data: {},
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getValidationComment(label) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/getValidationComment?labelId=${label}`,
      data: {},
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  updateData(id, data, label,username) {
   
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/updateData?labelId=${label}&username=${username}&jsonId=${id}`,
      data: data,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getLabelDataForDownload(label) {
    // label = encryptData(label);
     const config = {
       method: "post",
       url: API_BASE_URL + `/api/v1/getLabelDataForDownload?labelId=${label}`,
       data: {},
     };
     return dataDisplayRequest(config).then((response) => {
       return response;
     });
   }
   LogIndividualData(id, data, label,username) {
   
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/LogIndividualData?labelId=${label}&username=${username}&jsonId=${id}`,
      data: data,
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getEditedReportByLabel(label) {
    const config = {
      method: "post",
      url: API_BASE_URL + `/api/v1/getEditedReportByLabel?labelId=${label}`,
      data: {},
    };
    return dataDisplayRequest(config).then((response) => {
      return response;
    });
  }

  getLabelDataForExcelDownload(label) {
    // label = encryptData(label);
     const config = {
       method: "post",
       url: API_BASE_URL + `/api/v1/getLabelDataForExcelDownload?labelId=${label}`,
       data: {},
      responseType: 'arraybuffer'
     };
     return dataDisplayRequest(config).then((response) => {
       return response;
     });
   }

   getDirectionForUseData(label) {
    // label = encryptData(label);
     const config = {
       method: "post",
       url: API_BASE_URL + `/api/v1/getDirectionForUseDetails?labelId=${label}`,
       data: {},
     };
     return dataDisplayRequest(config).then((response) => {
       return response;
     });
   }


   getDirectionForUseDataCount(label) {
    // label = encryptData(label);
     const config = {
       method: "post",
       url: API_BASE_URL + `/api/v1/getDirectionForUseDataCount?labelId=${label}`,
       data: {},
     };
     return dataDisplayRequest(config).then((response) => {
       return response;
     });
   }

   getDirectionForUseDetailsWithPage(label, currentPage) {
    // label = encryptData(label);
     const config = {
       method: "post",
       url: API_BASE_URL + `/api/v1/getDirectionForUseDetailsWithPage?labelId=${label}&currentPage=${currentPage}`,
       data: {},
     };
     return dataDisplayRequest(config).then((response) => {
       return response;
     });
   }

}
