import TableCell from "../TableCell";
import React, { Suspense, lazy } from "react";
const Agent = React.lazy(()=> import("./Agent"));

const AgentRow = ({ data }) => {
  return (
    <>
      {data && (
        <>
          <div className="table-responsive">
            <table class="table  mt-2  table-bordered mb-0">
              <thead>
                <tr className="table-light">
                  <th scope="col">Type</th>
                  <th scope="col">Value</th>
                  {data && data.type ==="NonPersistentIdentifier" &&
                  <>
                  <th scope="col">Valid From</th>
                  <th scope="col">Valid To</th>
                  <th scope="col">Version No</th>
                  </>
                  }
                  
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <TableCell label={"Type"} data={data.type} />
                  </td>
                  <td>
                  {data.value && ( <TableCell label={"Value"} data={data.value} />)}
               {data.label && (<TableCell label={"Value"} data={data.label} />)} 
                   
                  </td>
                  {data && data.type ==="NonPersistentIdentifier" &&
                  <>
                  <td>
                    <TableCell label={"Valid From"} data={data.validFrom} />
                  </td>
                  <td>
                    <TableCell label={"Valid To"} data={data.validTo} />
                  </td>

                  <td>
                    <TableCell label={"Version No"} data={data.versionNo} />
                  </td>
                  </>
                  }
                </tr>
              </tbody>
            </table>
          </div>

          {data.authority && (
            <>
              <div className="fw-bold text-secondary mt-2">Authority</div>
              <Suspense fallback ={<div> Loading ...</div>}><Agent data={data.authority} /></Suspense>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AgentRow;
