import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import classNames from "classnames";
import styles from "./Sidebar.module.scss";
import {
  faAngleDoubleLeft,
  faComment,
  faClipboardCheck,
  faFilePen
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

const Sidebar = ({ data, activeSection, sectionRefs, commentRef, jumpTo,validationCommentRef ,editedReportRef}) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(true);
  };

  return (
    <>
      {show ? (
        <div
          className={classNames(styles.sidebarStyleMin, " bg-light text-dark")}
          style={{height:"90%", overflowY :"auto"}}
        >
          <button
            onClick={(e) => setShow(false)}
            className="btn btn-outline-light  btn-small"
            title="Click here the Expand"
          >
            <FontAwesomeIcon
              className="text-success"
              icon={faAngleDoubleLeft}
            />
          </button>

          {data.tradeName && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-1">Trade Name</Tooltip>}
            >
              <div
                style={{ borderTop: "1px solid lightgray" }}
                onClick={(e) => jumpTo(sectionRefs[0])}
                className={
                  activeSection === 0 ? styles.activeMin : styles.listItemMin
                }
              >
                1
              </div>
            </OverlayTrigger>
          )}

          {data.regulatoryAuthorisation && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Regulatory Authorisation</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[1])}
                className={
                  activeSection === 1 ? styles.activeMin : styles.listItemMin
                }
              >
                2
              </div>
            </OverlayTrigger>
          )}

          {data.territory && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Territory</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[2])}
                className={
                  activeSection === 2 ? styles.activeMin : styles.listItemMin
                }
              >
                3
              </div>
            </OverlayTrigger>
          )}

          {data.formulationIngredients && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Formulation Ingredients</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[3])}
                className={
                  activeSection === 3 ? styles.activeMin : styles.listItemMin
                }
              >
                4
              </div>
            </OverlayTrigger>
          )}

          {data.directionsForUse && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Directions For Use</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[4])}
                className={
                  activeSection === 4 ? styles.activeMin : styles.listItemMin
                }
              >
                5
              </div>
            </OverlayTrigger>
          )}

          {data.directionsForStorage && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Directions For Storage</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[5])}
                className={
                  activeSection === 5 ? styles.activeMin : styles.listItemMin
                }
              >
                6
              </div>
            </OverlayTrigger>
          )}

          {data.directionsForDisposal && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Directions For Disposal</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[6])}
                className={
                  activeSection === 6 ? styles.activeMin : styles.listItemMin
                }
              >
                7
              </div>
            </OverlayTrigger>
          )}

          {data.adverseEffects && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Adverse Effects</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[7])}
                className={
                  activeSection === 7 ? styles.activeMin : styles.listItemMin
                }
              >
                8
              </div>
            </OverlayTrigger>
          )}

          {data.risks && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Risks</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[8])}
                className={
                  activeSection === 8 ? styles.activeMin : styles.listItemMin
                }
              >
                9
              </div>
            </OverlayTrigger>
          )}

          {data.safetyInformation && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Safety Information</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[9])}
                className={
                  activeSection === 9 ? styles.activeMin : styles.listItemMin
                }
              >
                10
              </div>
            </OverlayTrigger>
          )}

          {data.additionalInstructionsLink && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="sect-2">Additional Instructions Link</Tooltip>
              }
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[10])}
                className={
                  activeSection === 10 ? styles.activeMin : styles.listItemMin
                }
              >
                11
              </div>
            </OverlayTrigger>
          )}

          {data.preparationType && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Preparation Type</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[11])}
                className={
                  activeSection === 11 ? styles.activeMin : styles.listItemMin
                }
              >
                12
              </div>
            </OverlayTrigger>
          )}

          {data.manufacturer && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Manufacturer</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[12])}
                className={
                  activeSection === 12 ? styles.activeMin : styles.listItemMin
                }
              >
                13
              </div>
            </OverlayTrigger>
          )}

          {data.marketedBy && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Marketed By</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[13])}
                className={
                  activeSection === 13 ? styles.activeMin : styles.listItemMin
                }
              >
                14
              </div>
            </OverlayTrigger>
          )}

          {data.brand && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Brand</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[14])}
                className={
                  activeSection === 14 ? styles.activeMin : styles.listItemMin
                }
              >
                15
              </div>
            </OverlayTrigger>
          )}

          {data.colour && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Colour</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[15])}
                className={
                  activeSection === 15 ? styles.activeMin : styles.listItemMin
                }
              >
                16
              </div>
            </OverlayTrigger>
          )}

          {data.odour && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Odour</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[16])}
                className={
                  activeSection === 16 ? styles.activeMin : styles.listItemMin
                }
              >
                17
              </div>
            </OverlayTrigger>
          )}

          {data.unNumber && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">UN Number</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[17])}
                className={
                  activeSection === 17 ? styles.activeMin : styles.listItemMin
                }
              >
                18
              </div>
            </OverlayTrigger>
          )}

          {data.storageClassificationCode && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="sect-2">Storage Classification Code</Tooltip>
              }
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[18])}
                className={
                  activeSection === 18 ? styles.activeMin : styles.listItemMin
                }
              >
                19
              </div>
            </OverlayTrigger>
          )}

          {data.modeOfActionDescription && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="sect-2">Mode Of Action Description</Tooltip>
              }
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[19])}
                className={
                  activeSection === 19 ? styles.activeMin : styles.listItemMin
                }
              >
                20
              </div>
            </OverlayTrigger>
          )}

          {data.resistanceInformation && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Resistance Information</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[20])}
                className={
                  activeSection === 20 ? styles.activeMin : styles.listItemMin
                }
              >
                21
              </div>
            </OverlayTrigger>
          )}

          {data.cropRotationGuidance && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Crop Rotation Guidance</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[21])}
                className={
                  activeSection === 21 ? styles.activeMin : styles.listItemMin
                }
              >
                22
              </div>
            </OverlayTrigger>
          )}

          {data.cropFailureGuidance && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Crop Failure Guidance</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[22])}
                className={
                  activeSection === 22 ? styles.activeMin : styles.listItemMin
                }
              >
                23
              </div>
            </OverlayTrigger>
          )}

          {data.summaryNotes && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Summary Notes</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[23])}
                className={
                  activeSection === 23 ? styles.activeMin : styles.listItemMin
                }
              >
                24
              </div>
            </OverlayTrigger>
          )}

          {data.otherMandatoryInformation && (
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="sect-2">Other Mandatory Information</Tooltip>
              }
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[24])}
                className={
                  activeSection === 24 ? styles.activeMin : styles.listItemMin
                }
              >
                25
              </div>
            </OverlayTrigger>
          )}

          {data.labelMetadata && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Label Metadata</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[25])}
                className={
                  activeSection === 25 ? styles.activeMin : styles.listItemMin
                }
              >
                26
              </div>
            </OverlayTrigger>
          )}

          {data.productPackage && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Product Package</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[26])}
                className={
                  activeSection === 26 ? styles.activeMin : styles.listItemMin
                }
              >
                27
              </div>
            </OverlayTrigger>
          )}

          {data.productActionType && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Product Action Type</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[27])}
                className={
                  activeSection === 27 ? styles.activeMin : styles.listItemMin
                }
              >
                28
              </div>
            </OverlayTrigger>
          )}

    {data.dangerousIngredientsWarning && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">dangerous Ingredients Warning</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[28])}
                className={
                  activeSection === 28 ? styles.activeMin : styles.listItemMin
                }
              >
                29
              </div>
            </OverlayTrigger>
          )}

           {data.successionalCropGuidance && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Successional Crop Guidance</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[29])}
                className={
                  activeSection === 29 ? styles.activeMin : styles.listItemMin
                }
              >
                30
              </div>
            </OverlayTrigger>
          )}
          {data.directionsForTransport && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Directions For Transport</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[30])}
                className={
                  activeSection === 30 ? styles.activeMin : styles.listItemMin
                }
              >
                31
              </div>
            </OverlayTrigger>
          )}
           {data.technicalSupportOrganisation && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Technical Support Organisation</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[31])}
                className={
                  activeSection === 31 ? styles.activeMin : styles.listItemMin
                }
              >
                32
              </div>
            </OverlayTrigger>
          )}
          {data.coformulantsQbaStatement && (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip id="sect-2">Coformulants Qba Statement</Tooltip>}
            >
              <div
                onClick={(e) => jumpTo(sectionRefs[32])}
                className={
                  activeSection === 32 ? styles.activeMin : styles.listItemMin
                }
              >
                33
              </div>
            </OverlayTrigger>
          )}
           
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="sect-2"> All Comments</Tooltip>}
          >
            <div
              onClick={(e) => jumpTo(commentRef)}
              className={styles.listItemMin}
            >
              <FontAwesomeIcon className="text-secondary" icon={faComment} />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="sect-2"> validation Comment</Tooltip>}
          >
            <div
              onClick={(e) => jumpTo(validationCommentRef)}
              className={styles.listItemMin}
            >
             <FontAwesomeIcon className="text-secondary" icon={faClipboardCheck} /> 
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="left"
            overlay={<Tooltip id="sect-2"> Edited Log Report</Tooltip>}
          >
            <div
              onClick={(e) => jumpTo(editedReportRef)}
              className={styles.listItemMin}
            >
             <FontAwesomeIcon className="text-secondary" icon={faFilePen} /> 
            </div>
          </OverlayTrigger>
        </div>
      ) : (
        <Offcanvas
          style={{ maxWidth: "350px" }}
          show={true}
          scroll={true}
          backdrop={true}
          placement={"end"}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton title="close">
            <Offcanvas.Title>Sections</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
             <ul class="list-group " style={{ cursor: "pointer" }}>
              {data.tradeName && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[0])}
                  className={
                    activeSection === 0
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  1 Trade Name
                </li>
              )}

              {data.regulatoryAuthorisation && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[1])}
                  className={
                    activeSection === 1
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  2 Regulatory Authorisation
                </li>
              )}

              {data.territory && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[2])}
                  className={
                    activeSection === 2
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  3 Territory
                </li>
              )}

              {data.formulationIngredients && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[3])}
                  className={
                    activeSection === 3
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  4 Formulation Ingredients
                </li>
              )}

              {data.directionsForUse && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[4])}
                  className={
                    activeSection === 4
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  5 Directions For Use
                </li>
              )}

              {data.directionsForStorage && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[5])}
                  className={
                    activeSection === 5
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  6 Directions For Storage
                </li>
              )}

              {data.directionsForDisposal && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[6])}
                  className={
                    activeSection === 6
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  7 Directions For Disposal
                </li>
              )}

              {data.adverseEffects && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[7])}
                  className={
                    activeSection === 7
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  8 Adverse Effects
                </li>
              )}

              {data.risks && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[8])}
                  className={
                    activeSection === 8
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  9 Risks
                </li>
              )}

              {data.safetyInformation && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[9])}
                  className={
                    activeSection === 9
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  10 Safety Information
                </li>
              )}

              {data.additionalInstructionsLink && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[10])}
                  className={
                    activeSection === 10
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  11 Additional Instructions Link
                </li>
              )}

              {data.preparationType && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[11])}
                  className={
                    activeSection === 11
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  12 Preparation Type
                </li>
              )}

              {data.manufacturer && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[12])}
                  className={
                    activeSection === 12
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  13 Manufacturer
                </li>
              )}

              {data.marketedBy && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[13])}
                  className={
                    activeSection === 13
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  14 Marketed By
                </li>
              )}

              {data.brand && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[14])}
                  className={
                    activeSection === 14
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  15 Brand
                </li>
              )}

              {data.colour && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[15])}
                  className={
                    activeSection === 15
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  16 Colour
                </li>
              )}

              {data.odour && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[16])}
                  className={
                    activeSection === 16
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  17 Odour
                </li>
              )}

              {data.unNumber && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[17])}
                  className={
                    activeSection === 17
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  18 UN Number
                </li>
              )}

              {data.storageClassificationCode && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[18])}
                  className={
                    activeSection === 18
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  19 Storage Classification Code
                </li>
              )}

              {data.modeOfActionDescription && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[19])}
                  className={
                    activeSection === 19
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  20 Mode Of Action Description
                </li>
              )}

              {data.resistanceInformation && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[20])}
                  className={
                    activeSection === 20
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  21 Resistance Information
                </li>
              )}

              {data.cropRotationGuidance && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[21])}
                  className={
                    activeSection === 21
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  22 Crop Rotation Guidance
                </li>
              )}

              {data.cropFailureGuidance && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[22])}
                  className={
                    activeSection === 22
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  23 Crop Failure Guidance
                </li>
              )}

              {data.summaryNotes && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[23])}
                  className={
                    activeSection === 23
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  24 Summary Notes
                </li>
              )}

              {data.otherMandatoryInformation && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[24])}
                  className={
                    activeSection === 24
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  25 Other Mandatory Information
                </li>
              )}

              {data.labelMetadata && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[25])}
                  className={
                    activeSection === 25
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  26 Label Metadata
                </li>
              )}

              {data.productPackage && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[26])}
                  className={
                    activeSection === 26
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  27 Product Package
                </li>
              )}

              {data.productActionType && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[27])}
                  className={
                    activeSection === 27
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  28 Product Action Type
                </li>
              )}

{data.dangerousIngredientsWarning && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[28])}
                  className={
                    activeSection === 28
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  29 Dangerous Ingredients Warning
                </li>
              )}
               {data.successionalCropGuidance && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[29])}
                  className={
                    activeSection === 29
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  30 Successional Crop Guidance
                </li>
              )}

              {data.directionsForTransport && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[30])}
                  className={
                    activeSection === 30
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  31 Directions For Transport
                </li>
              )}
              {data.technicalSupportOrganisation && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[31])}
                  className={
                    activeSection === 31
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  32 Technical Support Organisation
                </li>
              )}
                {data.coformulantsQbaStatement && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[32])}
                  className={
                    activeSection === 32
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  33 Co-formulants Qba Statement
                </li>
              )}
              {data.materialState && (
                <li
                  onClick={(e) => jumpTo(sectionRefs[33])}
                  className={
                    activeSection === 33
                      ? "list-group-item list-group-item-action py-1 active"
                      : "list-group-item list-group-item-action py-1"
                  }
                >
                  34 Material State
                </li>
              )}


              <li
                onClick={(e) => jumpTo(commentRef)}
                className="list-group-item list-group-item-action py-1"
              >
                <FontAwesomeIcon className="text-secondary me-2 " icon={faComment} />
                All Comments
              </li>
              <li
                onClick={(e) => jumpTo(validationCommentRef)}
                className="list-group-item list-group-item-action py-1"
              >
                <FontAwesomeIcon className="text-secondary me-2 " icon={faClipboardCheck} />
                validation Comment
              </li>
              <li
                onClick={(e) => jumpTo(editedReportRef)}
                className="list-group-item list-group-item-action py-1"
              >
                <FontAwesomeIcon className="text-secondary me-2 " icon={faFilePen} />
                Edited Log Report
              </li>
            </ul>
            
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default Sidebar;
