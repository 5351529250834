import React, { useEffect, useState } from "react";

import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { faSquareCheck, faShare , faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AxiosService from "../../Utils/AxiosService";

import { toast } from "react-toastify";

import TableView from "../TableView/TableView";
import LocalStorageService from "../../Auth/auth_service/AuthServiceStorage";
import Reactselect from "../../Components/Reactselect";
import { Button, Modal } from "react-bootstrap";
import "../TableView/Table.css";


const LabelView = () => {
  const apiService = new AxiosService();
  const authDetails = LocalStorageService.getAuthDetails();

  const [allLabelsPending, setAllLabelsPending] = useState([]);
  const [allLabelsComp, setAllLabelsComp] = useState([]);
  const [allLabelsAssign, setAllLabelsAssign] = useState([]);

  const [labelPendingLoader, setLabelPendingLoader] = useState(false);
  const [labelCompLoader, setLabelCompLoader] = useState(false);

  const [labelAssignLoader, setLabelAssignLoader] = useState(false);

  const [selectedLabel, setSelectedLabel] = useState("");

  const [selPendingLabel, setSelPendingLabel] = useState("");
  const [selCompLabel, setSelCompLabel] = useState("");
  const [selAssignLabel, setSelAssignLabel] = useState("");

  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);

  const [completeLoader, setCompleteLoader] = useState(false);
  const [assignLoader, setAssignLoader] = useState(false);
  const [assignBackLoader, setAssignBackLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [assignShow, setAssignShow] = useState(false);
  const [assignBackShow, setAssignBackShow] = useState(false);
  const [pendinBackShow, setPendingBackShow] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [editFlag , setEditFlag] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAssignModelClose = () => setAssignShow(false);
  const handleAssignModelShow = () => setAssignShow(true);

  const handleAssignBackModelClose = () => setAssignBackShow(false);
  const handleAssignBackModelShow = () => setAssignBackShow(true);
  
  const handlePendingBackModelClose = () => setPendingBackShow(false);
  const handlePendingBackModelShow = () => setPendingBackShow(true);

  const getAllLabelsPending = () => {

    setLabelPendingLoader(true);

    const auth_details = JSON.parse(authDetails);

    apiService
      .getAllLabels(auth_details.roles, "N", selectedLanguage)
      .then((response) => {
        setLabelPendingLoader(false);
        if (response.data) {
          setAllLabelsPending(response.data);
        }
      })
      .catch((error) => {
        setLabelPendingLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  const getAllLabelsComp = () => {
    setLabelCompLoader(true);

    const auth_details = JSON.parse(authDetails);

    apiService
      .getAllLabels(auth_details.roles, "Y",selectedLanguage)
      .then((response) => {
        setLabelCompLoader(false);
        if (response.data) {
          setAllLabelsComp(response.data);
        }
      })
      .catch((error) => {
        setLabelCompLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  const getLabelData = () => {
    setLoader(true);

    apiService
      .getLabelData(selectedLabel)
      .then((response) => {
        setLoader(false);
        if (response.data) {
          setData(response.data);
          LocalStorageService.setSelectedLabelId(selectedLabel);
       
         LocalStorageService.setSelectedLabelData(JSON.stringify(response.data));
          const sessionStorageUpdatedEvent = new Event('sessionStorageLabelUpdated');
          window.dispatchEvent(sessionStorageUpdatedEvent);
        }
      })
      .catch((error) => {
        setLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  const onCompleteLabel = () => {
    setCompleteLoader(true);

    const auth_details = JSON.parse(authDetails);


    apiService
      .labelStatusUpdate(selectedLabel, "Y",auth_details.id)
      .then((response) => {
        setCompleteLoader(false);
        setShow(false);

        getAllLabelsPending();
        getAllLabelsComp();
        getAllLabelsAssigned();

        setData({});
        setSelectedLabel("");
        setSelPendingLabel("");
        setSelCompLabel("");
        setSelAssignLabel("");
        setEditFlag(false)

        toast.success("Label updated successfully!");
      })
      .catch((error) => {
        setCompleteLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  useEffect(() => {
    if (selectedLabel) {
      setData({});
      getLabelData();
    }
  }, [selectedLabel]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (authDetails) {
      getAllLabelsPending();
      getAllLabelsComp();
      getAllLabelsAssigned();
    }
  }, []);

  const getAllLabelsAssigned = () => {
    setLabelAssignLoader(true);

    const auth_details = JSON.parse(authDetails);

    apiService
      .getAllLabels(auth_details.roles, "R",selectedLanguage)
      .then((response) => {
        setLabelAssignLoader(false);
        if (response.data) {
          setAllLabelsAssign(response.data);
        }
      })
      .catch((error) => {
        setLabelAssignLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  const onAssignLabel = () => {
    setAssignLoader(true);

    const auth_details = JSON.parse(authDetails);
    apiService
      .labelStatusUpdate(selectedLabel, "R",auth_details.id)
      .then((response) => {
        setAssignLoader(false);
        setAssignShow(false);

        getAllLabelsPending();
        getAllLabelsComp();
        getAllLabelsAssigned();

        setData({});
        setSelectedLabel("");
        setSelPendingLabel("");
        setSelCompLabel("");
        setSelAssignLabel("");
        setEditFlag(false);

        toast.success("Label updated successfully!");
      })
      .catch((error) => {
        setAssignShow(false);
        setAssignLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  const onAssignBackLabel = () => {
    setAssignBackLoader(true);

    const auth_details = JSON.parse(authDetails);
    apiService
      .labelStatusUpdate(selectedLabel, "N",auth_details.id)
      .then((response) => {
        setAssignBackLoader(false);
        setAssignBackShow(false);

        getAllLabelsPending();
        getAllLabelsComp();
        getAllLabelsAssigned();

        setData({});
        setSelectedLabel("");
        setSelPendingLabel("");
        setSelCompLabel("");
        setSelAssignLabel("");
        setEditFlag(false);

        toast.success("Label updated successfully!");
      })
      .catch((error) => {
        setAssignBackLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  // useEffect(() => {
  //  setSelectedLanguage(LocalStorageService.getSelectedLanguage())
  // }, [LocalStorageService.getSelectedLanguage()]);

  useEffect(() => {
    getAllLabelsPending();
    getAllLabelsComp();
    getAllLabelsAssigned();
    setData({});
    setSelectedLabel("");
    setSelPendingLabel("");
    setSelCompLabel("");
    setSelAssignLabel("");
    sessionStorage.removeItem("selectedLabelId");
    setEditFlag(false);
   }, [selectedLanguage]);

  
  useEffect(() => {
    const handleSessionStorageUpdate = () => {
      setSelectedLanguage(sessionStorage.getItem('selectedLanguage'));
    };

    window.addEventListener('sessionStorageUpdated', handleSessionStorageUpdate);

    return () => {
      window.removeEventListener('sessionStorageUpdated', handleSessionStorageUpdate);
    };
  }, []);

  const onPeningBackLabel = () => {
    setCompleteLoader(true);

    const auth_details = JSON.parse(authDetails);
    apiService
      .labelStatusUpdate(selectedLabel, "N",auth_details.id)
      .then((response) => {
        setCompleteLoader(false);
        setPendingBackShow(false);

        getAllLabelsPending();
        getAllLabelsComp();
        getAllLabelsAssigned();

        setData({});
        setSelectedLabel("");
        setSelPendingLabel("");
        setSelCompLabel("");
        setSelAssignLabel("");

        toast.success("Label updated successfully!");
      })
      .catch((error) => {
        setAssignBackLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  const handleDataChange = (newData) => {
    console.log(newData)
    setData(JSON.stringify(newData));
  };

  useEffect(() => {

  },[data]);

  return (
    <div style={{ marginRight: 35 }}>
      <div className="row  row-cols-1 row-cols-md-2 g-2">
        <div className="col-md-5">
          <Card>
            <Card.Header className=" h5">
              Pending Labels
              <span className="text-muted ms-1">
                ({allLabelsPending.length})
              </span>
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-sm-6">
                  <Reactselect
                    options={allLabelsPending}
                    loader={labelPendingLoader}
                    selectedLabel={selPendingLabel}
                    handleChange={(e) => {
                      setSelPendingLabel(e);
                      setEditFlag(true);
                      setSelCompLabel("");
                      setSelAssignLabel("");
                      setSelectedLabel(e.LABEL_ID);
                      localStorage.selectedLabel =e.LABEL_ID;
                    }}
                  />
                </div>
                <div className="col-sm-6">
                <div className="button-container">
                  <button
                    disabled={!selPendingLabel || assignLoader}
                    onClick={handleAssignModelShow}
                    className="btn btn-sm btn-secondary customBtn"
                  >
                    <>
                      <FontAwesomeIcon icon={faShare} className="me-1" />
                      reassign
                    </>
                  </button>
                  <button
                    disabled={!selPendingLabel || completeLoader}
                    onClick={handleShow}
                    className="btn btn-sm btn-success customBtn"
                  >
                    <>
                      <FontAwesomeIcon icon={faSquareCheck} className="me-1" />
                      complete
                    </>
                  </button>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-4">
          <Card>
            <Card.Header className=" h5">
              <FontAwesomeIcon
                icon={faShare}
                className="text-success me-1"
              />
              Reassigned Labels
              <span className="text-muted ms-1">({allLabelsAssign.length})</span>
            </Card.Header>
            <Card.Body>
            <div className="row">
                <div className="col-sm-6">
              <Reactselect
                options={allLabelsAssign}
                loader={labelAssignLoader}
                selectedLabel={selAssignLabel}
                handleChange={(e) => {
                  setSelAssignLabel(e);
                  setSelPendingLabel("");
                  setEditFlag(false);
                  setSelCompLabel("");
                  setSelectedLabel(e.LABEL_ID);
                  localStorage.selectedLabel =e.LABEL_ID;
                }}
              />
              </div>
              <div className="col-sm-4">
                  <button
                    disabled={!selAssignLabel || assignBackLoader}
                    onClick={handleAssignBackModelShow}
                    className="btn btn-sm btn-success customBtn"
                  >
                    <>
                      <FontAwesomeIcon icon={faRightFromBracket} flip="horizontal" className="me-1" />
                      assignback
                    </>
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-3">
          <Card>
            <Card.Header className=" h5">
              <FontAwesomeIcon
                icon={faSquareCheck}
                className="text-success me-1"
              />
              Completed Labels
              <span className="text-muted ms-1">({allLabelsComp.length})</span>
            </Card.Header>
            <Card.Body>
            <div className="row">
                <div className="col-8">
                   <Reactselect
                options={allLabelsComp}
                loader={labelCompLoader}
                selectedLabel={selCompLabel}
                handleChange={(e) => {
                  setSelCompLabel(e);
                  setSelPendingLabel("");
                  setSelAssignLabel("");
                  setSelectedLabel(e.LABEL_ID);
                  localStorage.selectedLabel =e.LABEL_ID;
                }}
              />
                  </div>
                  <div className="col-2">
                  <button
                   disabled={!selCompLabel || completeLoader }
                    onClick={handlePendingBackModelShow}
                    className="btn btn-secondary customBtn"
                    title="Move to Pending"
                    style={{fontSize:"14px"}}
                  >
                    <>
                    {/* <FontAwesomeIcon icon={faArrowRotateLeft}  /> */}
                    pending
                    </>
                  </button>
                </div>
              </div>

            </Card.Body>
          </Card>
        </div>
      </div>

      {loader && (
        <>
          <Card className="mt-2 ">
            <Card.Body>
              <div className="text-center p-3">
                <Spinner animation="border" />
              </div>
            </Card.Body>
          </Card>
        </>
      )}
      {data && data["type"] && (
        <>
          <TableView data={data} label={selectedLabel} editFlag={editFlag}  onDataChange={handleDataChange}/>
        </>
      )}

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>
          Label <strong>{selPendingLabel.LABEL_NO?.split("--")[0]}</strong> will be marked as
          completed, please confirm
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            disabled={completeLoader}
            onClick={onCompleteLabel}
          >
            {completeLoader ? (
              <>
                <Spinner animation="grow" size="sm" />
                <Spinner animation="grow" size="sm" />
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faSquareCheck} className="me-1" />
                Submit
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={assignShow} onHide={handleAssignModelClose}>
        <Modal.Body>
          Label <strong>{selPendingLabel.LABEL_NO?.split("--")[0]}</strong> will be sent back to
          MC, please confirm
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAssignModelClose}>
            Close
          </Button>
          <Button
            variant="success"
            disabled={assignLoader}
            onClick={onAssignLabel}
          >
            {assignLoader ? (
              <>
                <Spinner animation="grow" size="sm" />
                <Spinner animation="grow" size="sm" />
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faSquareCheck} className="me-1" />
                Submit
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered show={assignBackShow} onHide={handleAssignBackModelClose}>
        <Modal.Body>
          Label <strong>{selAssignLabel.LABEL_NO?.split("--")[0]}</strong> will be assign back to
          pending, please confirm
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAssignModelClose}>
            Close
          </Button>
          <Button
            variant="success"
            disabled={assignBackLoader}
            onClick={onAssignBackLabel}
          >
            {assignBackLoader ? (
              <>
                <Spinner animation="grow" size="sm" />
                <Spinner animation="grow" size="sm" />
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faSquareCheck} className="me-1" />
                Submit
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={pendinBackShow} onHide={handlePendingBackModelClose}>
        <Modal.Body>
          Label <strong>{selCompLabel.LABEL_NO?.split("--")[0]}</strong> will be assign back to
          pending, please confirm
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePendingBackModelClose}>
            Close
          </Button>
          <Button
            variant="success"
            disabled={completeLoader}
            onClick={onPeningBackLabel}
          >
            {assignBackLoader ? (
              <>
                <Spinner animation="grow" size="sm" />
                <Spinner animation="grow" size="sm" />
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faSquareCheck} className="me-1" />
                Submit
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default LabelView;
