const DetailRow = ({ data, label, highlight , enableEdit ,editFlag , idField}) => {
  return (
    <>
      {data && (
        <>
          <div className="fw-bold">
            {highlight ? (<span className="bg-warning">{label}:</span>):(<>{label}: </>)}
            
            </div>
          <div id={idField} className={enableEdit ? "enableEdit" : ""} >{data}</div>
        </>
      )}
    </>
  );
};

export default DetailRow;